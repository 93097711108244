import React from 'react'
import { Link } from 'gatsby'
import { Layout, SEO, Container } from '../common'
import coupon from '../../assets/images/webCoupon.png'
import mCoupon from '../../assets/images/phoneCoupon.png'
import { Wrapper, Button, Header } from './styles'

export const Coupon = () => (
  <Wrapper as={Container}>
    <SEO title="はやしやクーポン" location="/coupon" />
    <Header>
      <Link to="/">はやしやホルモン館</Link>
    </Header>
    <img
      srcset={`${mCoupon} 600w, ${coupon}`}
      alt="はやしやホルモン館 クーポン"
    />
    <Link to="/">
      <Button>Back</Button>
    </Link>
  </Wrapper>
)
// <img src={coupon} alt="はやしやホルモン館 クーポン" />
