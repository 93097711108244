import styled from 'styled-components'

export const Wrapper = styled.div`
  img {
    display: block;
    margin: 2rem auto;
  }
`
export const Header = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
  background: transparent;
  background-color: #191919;
  padding: 1.5rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: #efefef;
  }
`

export const Button = styled.button`
  display: block;
  padding: 12px 24px;
  margin: 2rem auto;
  color: #eee;
  background-color: #333;
  border-radius: 8px;
`
